<template>
  <div>
    <div class="filter-container">
      <CascadeRegion
        :value="listQuery.cascadeRegion"
        @valueFunc="getQueryValueFunc"
        type="queryCascadeRegion"
        :styleRef="{ width: '300px', marginRight: '4px', top: '-4px' }"
      />
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click.prevent="addPricingItem"
        >添加地区定价</el-button
      >
    </div>

    <el-skeleton
      style="width: 100%;overflow:auto;height: 509px;"
      :loading="pageData.current_page === 0 && tabLoading"
      animated
      class="infinite-list-wrapper"
    >
      <div v-infinite-scroll="load" infinite-scroll-distance="disabled">
        <el-descriptions
          class="margin-top"
          :column="2"
          size="medium"
          border
          :labelStyle="{ width: '130px', textAlign: 'center' }"
          v-for="(proItem, i) in pricingItems"
          :key="i"
        >
          <template slot="extra">
            <el-button
              v-if="!proItem.isEdit"
              type="primary"
              size="small"
              @click.prevent="editPricing(i, true)"
              >编辑</el-button
            >
            <el-button
              v-else
              type="primary"
              size="small"
              @click.prevent="savePricing(i)"
              :loading="proItem.butLoading"
              >保存</el-button
            >
            <el-popconfirm
              :title="
                proItem.data.status === 1
                  ? '确定要将改条数据状态改为【有效】吗？'
                  : '确定要将改条数据状态改为【作废】吗？'
              "
              @confirm="updateStatus(i)"
              style="margin: 0 8px;"
            >
              <el-button
                slot="reference"
                :type="proItem.data.status === 1 ? 'success' : 'danger'"
                size="small"
                :disabled="proItem.isEdit"
                >{{ proItem.data.status === 1 ? "有效" : "作废" }}</el-button
              >
            </el-popconfirm>
          </template>
          <!--          省市区街道-->
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location"></i>
              省/市/区/街道
            </template>
            <CascadeRegion
              :value="proItem.data.cascadeRegion"
              @valueFunc="getQueryValueFunc"
              :type="i + '-queryCascadeRegion'"
              v-if="proItem.isEdit"
              :styleRef="{ width: '100%' }"
            />
            <span v-else>{{
              proItem.data.cascadeRegionStr.length !== 0
                ? proItem.data.cascadeRegionStr.join("/")
                : "暂无设置"
            }}</span>
          </el-descriptions-item>
          <!--          状态-->
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-set-up"></i>
              状态
            </template>
            <el-tag
              :type="proItem.data.status === 1 ? 'danger' : 'success'"
              effect="dark"
              v-if="[0, 1].includes(proItem.data.status)"
            >
              {{ getValue(PRICING_STATUS, proItem.data.status) }}
            </el-tag>
          </el-descriptions-item>
          <!--          价格-->
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-operation"></i>
              价格
            </template>
            <el-row
              :gutter="10"
              style="margin: 6px 0;"
              v-for="(item, index) in proItem.data.price"
              :key="item.id"
              :class="item.length <= index && 'item-button'"
            >
              <!--类型-->
              <el-col :span="6">
                <el-select
                  v-model="item.type"
                  placeholder="请选择类型"
                  style="width: 100%"
                  class="filter-item"
                  clearable
                  v-if="proItem.isEdit"
                  :size="mediumType"
                >
                  <el-option
                    v-for="item in PRICING_PRIC_TYPE"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  />
                </el-select>
                <span v-else
                  >类型：{{ getValue(PRICING_PRIC_TYPE, item.type) }}</span
                >
              </el-col>
              <!--时长-->
              <el-col :span="6">
                <el-select
                  v-model="item.timing"
                  placeholder="请选择计时方式"
                  style="width: 100%"
                  class="filter-item"
                  clearable
                  v-if="proItem.isEdit"
                  :size="mediumType"
                >
                  <el-option
                    v-for="item in TIMING_TYPE"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  />
                </el-select>
                <span v-else
                  >计时方式：{{ getValue(TIMING_TYPE, item.timing) }}</span
                >
              </el-col>

              <!--空闲时长-->
              <el-col :span="6">
                <el-input
                  v-model="item.freeTime"
                  placeholder="请输入空闲时长(分钟)"
                  v-if="proItem.isEdit"
                  :size="mediumType"
                  @input="freeTimeChange(i, index, item.freeTime)"
                />
                <span v-else>空闲时长(分钟)：{{ item.freeTime }}</span>
              </el-col>

              <!--删除的按钮-->
              <el-col
                :span="6"
                style="text-align: right;"
                v-show="proItem.isEdit"
              >
                <el-button
                  @click.prevent="removeItem(i, index)"
                  type="danger"
                  size="small"
                  style="margin-top: 2px;"
                  >删除</el-button
                >
                <el-button
                  @click.prevent="addGiftService(i, index)"
                  type="success"
                  size="small"
                  style="margin-top: 2px;"
                  >添加服务</el-button
                >
              </el-col>

              <!--              服务 start-->
              <el-col :span="24" v-if="item.serves && item.serves.length !== 0">
                <el-row
                  v-for="(giftItem, gindex) in item.serves"
                  :gutter="10"
                  style="margin: 6px 0;"
                  :key="gindex"
                >
                  <el-col :span="6" class="serves-title serves-title-clor"
                    >服务{{ gindex + 1 }}：</el-col
                  >
                  <el-col :span="4">
                    <el-select
                      v-model="giftItem.serveId"
                      placeholder="请选择服务类型"
                      style="width: 100%"
                      class="filter-item"
                      clearable
                      v-if="proItem.isEdit"
                      :size="mediumType"
                      @change="deviceServeChange($event, i, index, gindex)"
                    >
                      <el-option
                        v-for="item in deviceServeOption"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                    <span v-else class="serves-title"
                      >类型：{{ giftItem.serveName }}</span
                    >
                  </el-col>
                  <el-col :span="4">
                    <el-input
                      v-model="giftItem.duration"
                      placeholder="请输入时长(分钟)"
                      v-if="proItem.isEdit"
                      :size="mediumType"
                    />
                    <span v-else class="serves-title"
                      >时长(分钟)：{{ giftItem.duration }}</span
                    >
                  </el-col>

                  <!--价格-->
                  <el-col :span="4">
                    <el-input
                      v-model="giftItem.price"
                      placeholder="请输入价格"
                      v-if="proItem.isEdit"
                      :size="mediumType"
                      @input="priceChange(i, index, gindex, giftItem.price)"
                    />
                    <span v-else>价格：{{ giftItem.price }}</span>
                  </el-col>

                  <el-col :span="6" v-show="proItem.isEdit">
                    <el-button
                      @click.prevent="removeGiftService(i, index, gindex)"
                      type="danger"
                      size="small"
                      style="margin-top: 2px;"
                      >删除</el-button
                    >
                  </el-col>
                </el-row>
              </el-col>
              <!--              服务 end-->
            </el-row>
            <!--添加定价按钮-->
            <el-row style="margin: 6px 0;" :gutter="10">
              <el-col :span="21">
                <el-button
                  type="success"
                  size="mini"
                  style="width: 100%"
                  @click.prevent="addItem(i)"
                  v-show="proItem.isEdit"
                  >添加定价</el-button
                >
              </el-col>
            </el-row>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <p v-if="tabLoading" style="text-align: center;">加载中...</p>
      <p v-if="noMore" style="text-align: center;">没有更多了</p>
    </el-skeleton>
  </div>
</template>

<script>
import {
  getPriceList,
  setPrice,
  switchPriceStatus,
  getDeviceServeList
} from "@/api/equipment";
import CascadeRegion from "@/views/components/cascadeRegion.vue";
import {
  getValue,
  PRICING_PRIC_TYPE,
  PRICING_STATUS,
  TIMING_TYPE
} from "@/utils/enumUtile";
export default {
  computed: {
    PRICING_STATUS() {
      return PRICING_STATUS;
    },
    PRICING_PRIC_TYPE() {
      return PRICING_PRIC_TYPE;
    },
    TIMING_TYPE() {
      return TIMING_TYPE;
    },
    noMore() {
      return this.pageData.current_page === this.pageData.last_page;
    },
    disabled() {
      return this.tabLoading || this.noMore;
    }
  },
  props: {
    typeProp: Object
  },
  watch: {
    typeProp(newVal) {
      this.typeObj = newVal;
      this.getList();
      this.getServePrice();
    }
  },
  components: { CascadeRegion },
  data() {
    return {
      typeObj: this.typeProp,
      nextId: 2,
      tabLoading: false,
      pricingItems: [],
      mediumType: "medium",
      listQuery: {
        page: 1,
        limit: 5,
        cascadeRegion: [],
        provinceId: "",
        cityId: "",
        areaId: "",
        streetId: ""
      },
      pageData: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0
      },
      loading: false,
      deviceServeOption: [] // 设备服务类型
    };
  },
  created() {
    this.getList();
    this.getServePrice();
  },
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList() {
      const typeObj = this.typeObj;
      if (!typeObj || Object.keys(typeObj).length === 0) {
        return;
      }
      const listQuery = this.listQuery;
      const params = {
        ...listQuery,
        typeId: typeObj.id
      };
      delete params.cascadeRegion;

      this.disabled = true; // 先置true
      this.tabLoading = true;

      getPriceList(params)
        .then(resp => {
          // 保存分页的数据
          const pageData = resp.data || {};
          // 保存数据
          let arrList = [];
          if (pageData.current_page !== 1) {
            arrList = this.pricingItems;
          }
          const data = resp.data.data || [];

          if (data && data.length !== 0) {
            data.map((item, key) => {
              const cascadeRegionArr = [];
              const cascadeRegionStrArr = [];
              if (item.province) {
                cascadeRegionArr.push(item.province_id);
                cascadeRegionStrArr.push(item.province.name);
              }
              if (item.city) {
                cascadeRegionArr.push(item.city_id);
                cascadeRegionStrArr.push(item.city.name);
              }
              if (item.area) {
                cascadeRegionArr.push(item.area_id);
                cascadeRegionStrArr.push(item.area.name);
              }
              if (item.street) {
                cascadeRegionArr.push(item.street_id);
                cascadeRegionStrArr.push(item.street.name);
              }
              const priceArr = JSON.parse(item.price);
              if (priceArr && priceArr.length !== 0) {
                priceArr.map(pItem => {
                  if (!pItem.serves) {
                    pItem.serves = [];
                  }
                });
              }
              arrList.push({
                data: {
                  id: item.id,
                  typeId: item.type_id,
                  provinceId: item.province_id,
                  cityId: item.city_id,
                  areaId: item.area_id,
                  streetId: item.street_id,
                  status: item.status,
                  price: priceArr,
                  cascadeRegion: cascadeRegionArr,
                  cascadeRegionStr: cascadeRegionStrArr
                },
                nextId: key,
                isEdit: false,
                butLoading: false
              });
            });
          }
          this.pricingItems = arrList;
          delete pageData.data;
          this.pageData = pageData;
          this.tabLoading = false;
        })
        .finally(() => {});
    },
    /**
     * 查询服务
     */
    getServePrice() {
      const typeProp = this.typeObj;
      const params = {
        page: 1,
        limit: 9999,
        typeId: typeProp.id
      };
      getDeviceServeList(params).then(resp => {
        this.deviceServeOption = resp.data.data;
      });
    },
    load() {
      const data = this.pageData;
      if (data.current_page < data.last_page) {
        this.handleCurrentChange(data.current_page + 1);
      }
    },
    removeItem(propIndex, index) {
      this.pricingItems[propIndex].data.price.splice(index, 1);
    },
    addItem(propIndex) {
      this.pricingItems[propIndex].data.price.push({
        type: "",
        timing: "", //计时方式
        freeTime: "", //空闲时长分钟
        serves: []
      });
    },

    /**
     * 添加 prop item 的框架
     */
    addPricingItem() {
      const typeObj = this.typeObj;
      const addItem = {
        data: {
          id: 0,
          typeId: typeObj.id,
          provinceId: "",
          cityId: "",
          areaId: "",
          streetId: "",
          status: "",
          price: [
            {
              type: "",
              timing: "", //计时方式
              freeTime: "", //空闲时长分钟
              serves: [], //服务,
              totalAmount: 0 // 总金额
            }
          ],
          cascadeRegion: [],
          cascadeRegionStr: []
        },
        nextId: 2,
        isEdit: true,
        butLoading: false
      };
      this.pricingItems.unshift(addItem);
    },

    /**
     * 编辑 prop item
     * @param propIndex
     * @param status
     */
    editPricing(propIndex, status) {
      this.pricingItems[propIndex].isEdit = status;
    },

    /**
     * 切换状态 prop item
     * @param propIndex
     */
    updateStatus(propIndex) {
      const pricingItem = this.pricingItems[propIndex];
      const params = {
        id: pricingItem.data.id,
        status: pricingItem.data.status === 0 ? 1 : 0
      };
      switchPriceStatus(params).then(resp => {
        if (resp.code === 0) {
          this.getList();
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
      });
    },

    /**
     * 确认 添加 prop item
     * @param propIndex
     */
    savePricing(propIndex) {
      const featureItem = this.pricingItems[propIndex];
      const price = featureItem.data.price;

      // 对服务做校验
      if (price && price.length !== 0) {
        let dataNullFlag = false;
        let flag = false;
        price.map(item => {
          if (item.serves.length === 0) {
            flag = true;
          }
          if (
            !item.freeTime ||
            !item.timing ||
            (!item.type && item.type !== 0)
          ) {
            dataNullFlag = true;
          }
        });
        if (dataNullFlag) {
          this.$notify({
            title: "提示",
            message: "数据不能为空，请检查数据是否填写完整",
            type: "warning",
            duration: 2000
          });
          return;
        } else if (flag) {
          this.$notify({
            title: "提示",
            message: "套餐内最低包含一项服务",
            type: "warning",
            duration: 2000
          });
          return;
        }
      }

      const newPrice = featureItem.data.price;
      // 算总价格
      newPrice.map(o => {
        let total = 0;
        o.serves.map(serverO => {
          total = total + parseInt(serverO.price, 10);
        });
        o.totalAmount = total;
      });

      this.pricingItems[propIndex].butLoading = true;
      const params = {
        ...featureItem.data,
        price: JSON.stringify(newPrice)
      };

      delete params.cascadeRegion;
      delete params.cascadeRegionStr;

      setPrice(params)
        .then(resp => {
          if (resp.code === 0) {
            this.$notify({
              title: "成功",
              message: resp.data,
              type: "success",
              duration: 2000
            });
          } else {
            this.$notify({
              title: "提示",
              message: resp.data,
              type: "warning",
              duration: 2000
            });
          }
        })
        .finally(() => {
          this.pricingItems[propIndex].butLoading = false;
          this.editPricing(propIndex, false);
          this.getList();
          this.pageData = {
            current_page: 0,
            last_page: 0,
            per_page: 0,
            total: 0
          };
        });
    },
    /**
     * 获取省市区街道
     * @param type
     * @param resultArr
     */
    getQueryValueFunc(type, resultArr) {
      const typeArr = type.split("-");
      const propIndex = typeArr[0];
      if (type === "queryCascadeRegion") {
        this.listQuery.cascadeRegion = resultArr;
        this.listQuery.provinceId = resultArr[0] || "";
        this.listQuery.cityId = resultArr[1] || "";
        this.listQuery.areaId = resultArr[2] || "";
        this.listQuery.streetId = resultArr[3] || "";
      } else if (typeArr[1] === "queryCascadeRegion") {
        this.pricingItems[propIndex].data.cascadeRegion = resultArr;
        if (resultArr && resultArr.length !== 0) {
          this.pricingItems[propIndex].data.provinceId = resultArr[0] || "";
          this.pricingItems[propIndex].data.cityId = resultArr[1] || "";
          this.pricingItems[propIndex].data.areaId = resultArr[2] || "";
          this.pricingItems[propIndex].data.streetId = resultArr[3] || "";
        } else {
          this.pricingItems[propIndex].data.provinceId = "";
          this.pricingItems[propIndex].data.cityId = "";
          this.pricingItems[propIndex].data.areaId = "";
          this.pricingItems[propIndex].data.streetId = "";
        }
      }
    },
    /**
     * 时长输入校验事件
     */
    durationChange(propIndex, index, num) {
      let str = num;
      const len1 = str.substr(0, 1);
      //1.如果第一位是0，后面输入任何数字都直接代替
      if (str.length > 1 && (len1 === 0 || len1 === "0")) {
        str = str.substr(1, 1);
      }
      //2.第一位不能是.
      if (len1 === ".") {
        str = "";
      }
      //2.第一位不能是.
      if (len1 === "0" || len1 === 0) {
        str = "";
      }
      //3.最后进行正则替换，去掉所有的小数位
      str = str.replace(/[^\d^]+/g, ""); // 保留数字和小数点
      this.pricingItems[propIndex].data.price[index].duration = str;
      return str;
    },
    /**
     * 价格输入校验事件
     */
    priceChange(propIndex, index, gindex, num) {
      let str = num;
      const priceDataRef = this.pricingItems[propIndex].data.price[index];
      const len1 = str.substr(0, 1);
      if (priceDataRef.type !== 0 && (len1 === 0 || len1 === "0")) {
        str = "";
      }
      this.pricingItems[propIndex].data.price[index].serves[gindex].price = str;
      return str;
    },
    /**
     * 空闲时长校验事件
     * @param propIndex
     * @param index
     * @param num
     */
    freeTimeChange(propIndex, index, num) {
      let str = num;
      return str;
    },
    /**
     * 添加服务
     * @param propIndex
     * @param index
     */
    addGiftService(propIndex, index) {
      const obj = {
        serveId: "",
        serveName: "",
        duration: "",
        price: ""
      };
      this.pricingItems[propIndex].data.price[index].serves.unshift(obj);
    },
    /**
     * 改变
     * @param val
     * @param i
     * @param index
     * @param gindex
     */
    deviceServeChange(val, propIndex, index, gindex) {
      const deviceServe = this.deviceServeOption.find(item => item.id === val);
      this.pricingItems[propIndex].data.price[index].serves[gindex].serveName =
        deviceServe.name;
    },
    /**
     * 删除服务
     * @param propIndex
     * @param itemIndex
     * @param gindex
     */
    removeGiftService(propIndex, itemIndex, gindex) {
      this.pricingItems[propIndex].data.price[itemIndex].serves.splice(
        gindex,
        1
      );
    }
  },
  destroyed() {
    this.pricingItems = [];
  }
};
</script>
<style scoped lang="scss">
.el-descriptions {
  margin-bottom: 18px;
}
.item-button {
  border-bottom: 1px solid #dcdfe6;
  padding: 10px 0;
}
.serves-title {
  text-align: right;
  line-height: 36px;
}
.serves-title-clor {
  color: #ffba00;
}
</style>
